:root {
    --bs-tertiary-bg: white;
}

body {
    margin: 0;
    font-family: 'Assistant', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.simple-button {
    font-size: 19px !important;
    border-radius: 10px !important;
    width: 120px;
    height: 50px;
}

.simple-button.blue {
    background-color: #251FB6 !important;
    color: white !important;
}

.simple-button[disabled] {
    background-color: #7B7B7B !important;
}

.simple-button.outlined-blue {
    color: #251FB6 !important;
    border: 1px solid #251FB6;
}

.simple-button.red {
    background-color: #de3232 !important;
    color: white !important;
}

.simple-button.white {
    color: black !important;
    background-color: white !important;
    border: 1px solid #DDD !important;
}

.simple-button.small {
    font-size: 12px !important;
    height: 34px !important;
}

.simple-button.medium {
    font-size: 16px !important;
    height: 38px !important;
}

.simple-button.medium-140 {
    width: 140px !important;
}

.simple-button.long {
    width: 150px !important;
}

.simple-button.auto {
    width: auto!important;
}

.text-gray {
    color: #7B7B7B;
}

.text-small {
    font-size: 12px;
}

.text.blue {
    color: #251FB6;
}

@media screen and (min-width: 768px) {
    .slick-slide > div {
        margin: 0 15px; /* Adjust as necessary */
    }
}

@keyframes rotate360 {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.rotating {
    animation: rotate360 2s linear infinite;
}

.faq-accordion .accordion-item {
    border: none;
}

.faq-accordion .accordion-button {
    position: relative;
    background-color: #fff;
    color: #333;
    border: none;
    padding-right: 30px;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
    line-height: 1;
    padding-top: 0;
}

.faq-accordion .accordion-button:not(.collapsed)::before {
    content: "\2212"; /* Minus sign */
    position: absolute;
    right: 10px;
    color: #333;
}

.faq-accordion .accordion-button.collapsed::before {
    content: "\002B"; /* Plus sign */
    position: absolute;
    right: 10px;
    color: #333;
}

.faq-accordion .accordion-button::after {
    display: none; /* Hide default icons */
}

/* Style for the vertical connector line */
.faq-accordion .accordion-button.collapsed:not(:last-child) {
    border-bottom: none; /* Remove the border */
}

.faq-accordion .accordion-button:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 15px; /* Align with the plus/minus sign */
    bottom: 0;
    width: 2px;
    height: 100%;
    z-index: -1;
}

.faq-accordion .accordion-button:not(.collapsed)::after {
    height: 50%;
    bottom: 50%;
}

.accordion-button:focus:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:focus {
    box-shadow: none;
}